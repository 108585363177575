<template>
  <div>

    <!-- header -->
    <section v-if="dataExist" class="detail-hero mb-4">
      <div class="container text-white">
        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-lg-12 col-md-12 col-sm-12 detail-header-title flex-column  w-100">
            <div v-if="dataExist" class="detail-assignment-header d-flex justify-content-between align-items-center">
              <h3>{{ data.nama_jabatan }}&nbsp;
                <a @click="generatePdf" class="btn text-white" :class="{ 'btn-ternary': !unduhDisable , 'btn-secondary': unduhDisable, 'disabled': unduhDisable }">Unduh</a>
              </h3>
              <a @click="toSuntingTka('Nama Jabatan', data)" class="btn text-light fw-medium text-bold-custom">
                <i class="sunting-btn bi bi-pencil-square"></i> &nbsp; Sunting Data
              </a>
            </div>
            <div v-else class="placeholder-glow">
              <h3 class="placeholder col-6"></h3>
            </div>
            <div class="detail-assignment-header d-flex justify-content-between align-items-center">
              <p style="font-family:arial;margin:0px;">
                Padanan Nama Jabatan: {{ data.padanan_nama_jabatan !== '' && data.padanan_nama_jabatan !== undefined ? data.padanan_nama_jabatan : '-' }}
              </p>
              <!-- <i
                class="bi bi-info-circle"
                data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-custom-class="custom-tooltip"
                :data-bs-title="ringkasanTugasTooltip"
              ></i> -->
              <!-- <a @click="toSuntingTka('Kode Jabatan', data)" class="btn text-light fw-medium text-bold-custom">
                <i class="sunting-btn bi bi-pencil-square"></i> &nbsp; Sunting Data
              </a> -->
            </div>
            <div class="detail-assignment-header d-flex justify-content-between align-items-center">
              <p style="font-family:arial;margin:0px;">Kode Jabatan: {{ data.kode_jabatan }}</p>
              <a @click="toSuntingTka('Kode Jabatan', data)" class="btn text-light fw-medium text-bold-custom">
                <i class="sunting-btn bi bi-pencil-square"></i> &nbsp; Sunting Data
              </a>
            </div>
            <p style="font-family:arial">
              <a class="detail-clickable">Tenaga Kerja Asing (TKA)</a>
              <br>Kategori/Lapangan Usaha <b><a @click="toTka(data.sectorId)" class="detail-clickable">{{ `${data.kode_sector} ${data.sector}` }}</a></b>
              <br>Golongan Pokok <b><a @click="toTka(data.sectorId, data.subSectorId)" class="detail-clickable">{{ `${data.kode} ${data.desc}` }}</a></b><br>
            </p>

          </div>
        </div>

        <div class="border-bottom mt-2 mb-4"></div>

        <div class="detail-header-description">
          <div class="detail-assignment-header d-flex justify-content-between align-items-center">
            <div class="d-flex" style="gap: 5px">
              <h4 class="fw-medium">
                Ringkasan Uraian Tugas
              </h4>
              <i
                class="bi bi-info-circle"
                data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-custom-class="custom-tooltip"
                :data-bs-title="ringkasanTugasTooltip"
              ></i>
            </div>
            <a @click="toSuntingTka('Ringkasan Uraian Tugas', data)" class="btn text-light fw-medium text-bold-custom">
              <i class="sunting-btn bi bi-pencil-square"></i> &nbsp; Sunting Data
            </a>
              
          </div>
          <p style="font-family:arial;font-weight:normal">
            {{ data.ringkasan_uraian_tugas }}
          </p>
        </div>
        <div class="detail-header-example">
          <p>

          </p>
        </div>
      </div>
    </section>

    <!-- header placeholder -->
    <section v-else class="detail-hero mb-4 placeholder-glow">
      <div class="container text-white">
        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-lg-10 col-md-10 col-sm-12 detail-header-title flex-column">
            <div class="">
              <h3 class="placeholder col-6"></h3>
            </div>
            <p style="font-family:arial" class="placeholder col-2"></p>
            <div style="font-family:arial">
              <a @click="toTka" class="detail-clickable placeholder">TKA</a>
              <div>
                <span class="placeholder col-8"></span>
              </div>
              <div>
                <span class="placeholder col-6"></span>
              </div>
            </div>

          </div>
        </div>

        <div class="border-bottom mt-2 mb-4"></div>

        <div class="detail-header-description">
          <div class="detail-assignment-header d-flex justify-content-between align-items-center">
            <div class="d-flex" style="gap: 5px">
              <h4 class="fw-medium">
                Ringkasan Uraian Tugas
              </h4>
              <i
                class="bi bi-info-circle"
                data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-custom-class="custom-tooltip"
                :data-bs-title="ringkasanTugasTooltip"
              ></i>
            </div>
            <a @click="toSuntingTka('Ringkasan Uraian Tugas', data)" class="btn text-light fw-medium text-bold-custom">
              <i class="sunting-btn bi bi-pencil-square"></i> &nbsp; Sunting Data
            </a>
              
          </div>
          <p style="font-family:arial;font-weight:normal" class="d-flex flex-column gap-2">
            <span class="placeholder col-9"></span>
            <span class="placeholder col-4"></span>
          </p>
        </div>
        <div class="detail-header-example">
          <p>

          </p>
        </div>
      </div>
    </section>

    <section class="detail-content mb-5">
      <div class="container">
        <div class="card border-0 shadow-lg">
          <div class="card-body">
            <div class="w-100">
              <div class="scroller scroller-left float-start mt-2">
                  <i class="bi bi-caret-left-fill"></i>
              </div>
              <div class="scroller scroller-right float-end mt-2">
                  <i class="bi bi-caret-right-fill"></i>
              </div>
              <div class="wrapper-nav d-flex" style="overflow: auto;">

                <nav class="nav nav-pills mt-2 d-none d-sm-none d-md-flex d-lg-flex" id="myTab" role="tablist" style="position: absolute; left: 0px; top: 0px;">
                  <a 
                    style="font-family:arial" class="text-bolder-custom nav-item nav-link pointer me-3 active btn-primary"
                    data-bs-toggle="tab" data-bs-target="#tab1" role="tab" aria-controls="public"
                    aria-selected="true"
                  >
                    Profil
                  </a>
                  <a 
                    style="font-family:arial" class="text-bolder-custom nav-item nav-link pointer text-ternary me-3"
                    href="https://proglat.kemnaker.go.id" role="tab" target="_blank"
                  ><i class="bi bi-box-arrow-up-right"></i> 
                    Program Pelatihan
                  </a>
                  <a style="font-family:arial" class="text-bolder-custom nav-item nav-link pointer text-ternary"
                    href="https://karirhub.kemnaker.go.id" role="tab" target="_blank"
                  >
                    <i class="bi bi-box-arrow-up-right"></i>
                    Informasi Lowongan
                  </a>
                </nav>

                <nav class="nav nav-pills mt-2 d-flex d-sm-flex d-md-none d-lg-none flex-nowrap" style="position: absolute; left: 0px; top: 0px;">
                  <a class="text-bolder-custom nav-item nav-link pointer me-3 active btn-primary">
                    Profil
                  </a>
                  <a class="text-bolder-custom btn btn-ternary-custom nav-item nav-link active me-3 ">
                    <i class="bi bi-box-arrow-up-right"></i> 
                    Program Pelatihan
                  </a>
                  <a class="text-bolder-custom btn btn-ternary-custom nav-item nav-link active me-3 ">
                    <i class="bi bi-box-arrow-up-right"></i> 
                    Informasi Lowongan
                  </a>
                </nav>
              </div>
              <div class="tab-content p-3" id="myTabContent">
                <div role="tabpanel" class="tab-pane fade active show mt-2" id="tab1"
                  aria-labelledby="public-tab"
                >
                  <!-- Rincian Tugas -->
                  <div class="detail-assignment">

                    <div class="detail-assignment-header d-flex justify-content-between align-items-center">
                      <div style="font-family:arial;font-weight:bold;font-size:14pt">
                        Rincian Tugas
                        <i
                          class="bi bi-info-circle"
                          style="color: #0d6efd"
                          data-bs-toggle="tooltip" data-bs-placement="top"
                          data-bs-custom-class="custom-tooltip"
                          :data-bs-title="rincianTugasTooltip"
                        ></i>
                      </div>
                      <a @click="toSuntingTka('Rincian Tugas', data.rincianTugas)" class="btn text-ternary fw-medium text-bold-custom">
                        <i class="bi bi-pencil-square"></i> &nbsp; Sunting Data
                      </a>
                    </div>

                    <div v-if="dataExist" class="detail-assignment-content">
                      <table
                        style="padding: 2px"
                        v-for="(tugas, idx) in data.rincianTugas"
                        :key="idx"
                      >
                        <tr>
                          <td>&nbsp;&nbsp;</td>
                          <td
                            style="vertical-align:top;font-family: arial;font-weight:normal;font-size:12pt"
                          >
                            {{ `${idx + 1}.` }}
                          </td>
                          <td>&nbsp;</td>
                          <td
                            style="vertical-align:top;font-family: arial;font-weight:normal;font-size:12pt"
                          >
                            {{ tugas.rincian_tugas }}
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div v-else class="detail-assignment-content">
                      <p class="d-flex flex-column gap-2 placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-2"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-2"></span>
                        <span class="placeholder col-6"></span>
                      </p>
                    </div>
                  </div>
                  <div class="border-bottom my-3"></div>


                  <!-- Syarat Jabatan -->
                  <div class="job-requirements">
                    <div
                      class="job-requirements-header d-flex justify-content-between align-items-center"
                    >
                      <div style="font-family:arial;font-weight:bold;font-size:14pt"
                      >Syarat Jabatan
                      </div>
                    </div>

                    <div class="job-requirements-content">

                      <div v-for="(syarat, index) in syarat" :key="index">

                        <!-- title -->
                        <div class="education-header d-flex justify-content-between align-items-center">
                          <div 
                            style="font-family:arial;font-weight:bold;font-size:13pt"
                          >
                            {{ syarat.title }}
                            <i
                              class="bi bi-info-circle"
                              style="color: #0d6efd"
                              data-bs-toggle="tooltip" data-bs-placement="top"
                              data-bs-custom-class="custom-tooltip"
                              :data-bs-title="tooltipSyarat[index]"
                            ></i>
                          </div>
                          <button @click="toSuntingTka(syarat.title, data.syarat[syarat.prop])" class="btn text-ternary fw-medium text-bold-custom">
                            <i class="bi bi-pencil-square"></i> &nbsp; Sunting Data
                          </button>
                        </div>

                        <!-- content -->
                        <div v-if="dataExist" class="education-content">
                          <div v-if="Array.isArray(data.syarat[syarat.prop]) && data.syarat[syarat.prop].length > 0">
                            <div v-for="(isi, idx) in data.syarat[syarat.prop]" :key="idx">
                              <table style="padding: 2px">
                                <tr>
                                  <td>&nbsp;&nbsp;</td>
                                  <td
                                    style="vertical-align:top;font-family: arial;font-weight:normal;font-size:12pt">
                                    {{ `${idx + 1}.` }}
                                  </td>
                                  <td>&nbsp;</td>
                                  <td
                                    style="vertical-align:top;font-family: arial;font-weight:normal;font-size:12pt">
                                    {{ syarat.prop == 'upayaFisik' || syarat.prop == 'bakat' || syarat.prop == 'minat' || syarat.prop == 'temperamen' ? `${isi[syarat.desc]} (${isi.r_kode})` : isi[syarat.desc] }}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div v-else-if="data.syarat[syarat.prop] !== 0 && data.syarat[syarat.prop] !== '' && data.syarat[syarat.prop] !== undefined && data.syarat[syarat.prop].length !== 0">
                            <table style="padding: 2px">
                              <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td
                                  style="vertical-align:top;font-family: arial;font-weight:normal;font-size:12pt"
                                  colspan="3"
                                  >&nbsp;{{ syarat.prop == 'upayaFisik' || syarat.prop == 'bakat' || syarat.prop == 'minat' || syarat.prop == 'temperamen' ? `${data.syarat[syarat.prop][syarat.desc]} (${data.syarat[syarat.prop].r_kode})` : data.syarat[syarat.prop][syarat.desc] }}</td>
                              </tr>
                            </table>
                          </div>
                          <div v-else>
                            <table style="padding: 2px">
                              <tr>
                                <td>&nbsp;&nbsp;</td>
                                <td
                                  style="vertical-align:top;font-family: arial;font-weight:normal;font-size:12pt"
                                  colspan="3"
                                  >&nbsp; Data Belum Tersedia</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div v-else class="education-content">
                          <p class="d-flex flex-column gap-2 placeholder-glow">
                            <span class="placeholder col-3"></span>
                            <span class="placeholder col-2"></span>
                            <span class="placeholder col-1"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div class="tab-pane fade mt-2" id="tab2" role="tabpanel"
                    aria-labelledby="group-dropdown2-tab">
                    This is the content of Tab 2...
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- sunting modal -->
    <div class="modal fade" id="suntingModal" tabindex="-1" aria-labelledby="suntingModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl modal-fullscreen-sm-down modal-fullscreen-md-down">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="suntingModalLabel">{{ 'Sunting ' +  modal.title }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <section v-if="modal.title == 'Kode Jabatan'">
              <table ref="tableScrollable" class="table table-search table-borderless table-striped">
                <thead>
                  <tr>
                    <th class="align-items-center">No</th>
                    <th>Uraian</th>
                    <th>Suntingan</th>
                    <th>Komentar
                      <i
                        class="bi bi-info-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-custom-class="custom-tooltip"
                        data-bs-title="Catatan untuk Kemnaker"
                      ></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in modal.list" :key="idx">
                    <td>
                      {{ idx + 1 }}
                    </td>
                    <td>
                      <textarea class="form-control" rows="2" readonly v-model="list.currVal"></textarea>
                    </td>
                    <td>
                      <el-select
                        v-model="list.editedVal"
                        clearable
                        filterable
                        :placeholder="''"
                        no-match-text="Tidak ada data yang sesuai"
                        no-data-text="Tidak ada data"
                        remote
                        :remote-method="kodeJabatanMasterSearch"
                        :loading="loadingKodeJabatanSearch"
                        loading-text="Loading"
                      >
                        <el-option v-for="(option, idx) in master.kodeJabatan" :key="idx" :value="option.kode" :label="`${option.kode} ${option.golongan}`"></el-option>
                      </el-select>
                      <!-- <textarea class="form-control" rows="2" v-model="list.editedVal"></textarea> -->
                    </td>
                    <td>
                      <textarea class="form-control" rows="2" v-model="list.komentar"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div ref="tableBottom"></div>
            </section>

            <section v-else-if="modal.title == 'Ringkasan Uraian Tugas' || modal.title == 'Nama Jabatan'">
              <div v-for="(list, idx) in modal.list" :key="idx">
                <table class="table table-search table-borderless">
                  <thead>
                    <tr>
                      <th v-if="modal.title == 'Ringkasan Uraian Tugas'">Ringkasan Uraian Tugas</th>
                      <th v-else>Nama Jabatan</th>
                      <th>Suntingan</th>
                      <th>Komentar
                        <i
                          class="bi bi-info-circle"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-custom-class="custom-tooltip"
                          data-bs-title="Catatan untuk Kemnaker"
                        ></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <textarea class="form-control" rows="4" readonly v-model="list.currVal"></textarea>
                      </td>
                      <td>
                        <textarea class="form-control" rows="4" v-model="list.editedVal"></textarea>
                      </td>
                      <td>
                        <textarea class="form-control" rows="4" v-model="list.komentar"></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- <div class="mb-3">
                  <label class="form-label">Ringkasan Uraian Tugas</label>
                  <textarea class="form-control" rows="2" readonly v-model="list.currVal"></textarea>
                </div>
                <div class="mb-3">
                  <label class="form-label">Suntingan</label>
                  <textarea class="form-control" rows="2" v-model="list.editedVal"></textarea>
                </div> -->

                <br>
              </div>
            </section>

            <section v-else-if="modal.title == 'Rincian Tugas' || modal.title == 'Pengetahuan Kerja' || modal.title == 'Pelatihan Kerja'">
              <div class="d-flex justify-content-end">
                <button @click="modalSuntingAddDataTka(modal.title)" class="btn btn-primary">Tambah</button>
              </div>
              <br>
              <table ref="tableScrollable" class="table table-search table-borderless table-striped">
                <thead>
                  <tr>
                    <th class="align-items-center">No</th>
                    <th>Uraian</th>
                    <th>Suntingan</th>
                    <th>Komentar
                      <i
                        class="bi bi-info-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-custom-class="custom-tooltip"
                        data-bs-title="Catatan untuk Kemnaker"
                      ></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in modal.list" :key="idx">
                    <td>
                      {{ idx + 1 }}
                    </td>
                    <td>
                      <textarea class="form-control" rows="2" readonly v-model="list.currVal"></textarea>
                    </td>
                    <td>
                      <textarea class="form-control" rows="2" v-model="list.editedVal"></textarea>
                    </td>
                    <td>
                      <textarea class="form-control" rows="2" v-model="list.komentar"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div ref="tableBottom"></div>

              <!-- <div v-for="(list, idx) in modal.list" :key="idx">
                <div class="mb-3">
                  <label class="form-label">{{ `${idx + 1}.` }}</label>
                  <textarea class="form-control" rows="2" v-model="list.editedVal"></textarea>
                </div>
                <div class="mb-3">
                  <label class="form-label">Komentar</label>
                  <textarea class="form-control" rows="1" v-model="list.komentar"></textarea>
                </div>
                <br>
              </div> -->

            </section>

            <section v-else>

              <div class="d-flex justify-content-end">
                <button @click="modalSuntingAddDataTka(modal.title)" class="btn btn-primary">Tambah</button>
              </div>
              <br>
              <table class="table table-search table-striped table-borderless table-sm">
                <thead>
                  <tr>
                    <th class="align-items-center">No</th>
                    <th>Uraian</th>
                    <th>Suntingan</th>
                    <th>Komentar
                      <i
                        class="bi bi-info-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-custom-class="custom-tooltip"
                        data-bs-title="Catatan untuk Kemnaker"
                      ></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in modal.list" :key="idx">
                    <td>
                      {{ idx + 1 }}
                    </td>
                    <td>
                      <textarea class="form-control" rows="2" readonly v-model="list.currVal"></textarea>
                    </td>
                    <td>
                      <!-- <div class="w-100">
                        <el-select v-model="list.editedVal" clearable filterable :placeholder="''" no-match-text="Tidak ada data yang sesuai" no-data-text="Tidak ada data">
                          <el-option v-for="(option, idx) in options" :key="idx" :value="option" :label="getSelectValue(option)"></el-option>
                        </el-select>
                      </div> -->
                      <el-select
                        v-if="modal.title == 'Pendidikan'"
                        v-model="list.editedVal"
                        clearable
                        filterable
                        :placeholder="''"
                        no-match-text="Tidak ada data yang sesuai"
                        no-data-text="Tidak ada data"
                        remote
                        :remote-method="pendidikanMasterSearch"
                      >
                        <el-option v-for="(option, idx) in options" :key="idx" :value="option.pendidikan" :label="option[selectedMaster]"></el-option>
                      </el-select>

                      <el-select v-else v-model="list.editedVal" clearable filterable :placeholder="''" no-match-text="Tidak ada data yang sesuai" no-data-text="Tidak ada data">
                        <el-option v-for="(option, idx) in master[selectedMaster]" :key="idx" :value="option[selectedMaster]" :label="`(${option.kode}) ${option[selectedMaster]}`"></el-option>
                      </el-select>
                      <!-- <select class="form-select w-50" :value="list" clearable>
                        <option v-for="(option, idx) in options" :key="idx">{{ getSelectValue(option) }}</option>
                      </select> -->
                    </td>
                    <td>
                      <textarea class="form-control" rows="2" v-model="list.komentar"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div ref="tableBottom"></div>

              <!-- <el-select v-model="value1" multiple placeholder="">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> -->
              <!-- <div v-for="(list, idx) in modal.list" :key="idx">
                <div class="mb-3">
                  <label class="form-label">{{ `${idx + 1}.` }}</label>
                  <textarea class="form-control" rows="2" v-model="list.editedVal"></textarea>
                </div>
                <div class="mb-3">
                  <label class="form-label">Komentar</label>
                  <textarea class="form-control" rows="1" v-model="list.komentar"></textarea>
                </div>
                <br>
              </div> -->
            </section>

          </div>
          <div class="modal-footer">
            <button ref="closeSuntingModalBtn" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
            <button type="button" class="btn btn-primary" @click="draftSunting">Simpan Draft</button>
            <button type="button" class="btn btn-success" @click="submitSunting">Submit</button>
          </div>
        </div>
      </div>
    </div>

    <button ref="suntingModalBtn" @click.prevent="modalBtnClick" type="button" style="display: none;" data-bs-toggle="modal" data-bs-target="#suntingModal">
    </button>

    <vue-html2pdf
      v-if="isUserLogin"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="`Detail Jabatan TKA: ${data.nama_jabatan}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"

      ref="html2Pdf"
    >
    <!-- @progress="onProgress($event)"
    @hasStartedGeneration="hasStartedGeneration()"
    @hasGenerated="hasGenerated($event)" -->

      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <PDFSheet :informasi_id="informasi_id" @failedGenerate="failedGeneratePdf" />
      </section>
    </vue-html2pdf>

  </div>
</template>

<script>
import api from './api.js'
import apiKJN from '../kamusJabatanNasional/api.js'
import { Select, Option, Loading } from 'element-ui'
import swal from 'sweetalert2'
import PDFSheet from '../pdf/detailPdf2Tka.vue'
import VueHtml2pdf from 'vue-html2pdf'
import auth from '../../helpers/auth.js'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Loading.name]: Loading,
    PDFSheet,
    VueHtml2pdf
  },
  data () {
    return {
      dataExist: false,
      data: {},
      syarat: [
        {
          title: 'Pendidikan',
          prop: 'pendidikan',
          desc: 'pendidikan'
        },
        {
          title: 'Kompetensi',
          prop: 'kompetensi',
          desc: 'kompetensi'
        },
        {
          title: 'Pengetahuan Kerja',
          prop: 'pengetahuanKerja',
          desc: 'pengetahuan_kerja'
        },
        {
          title: 'Pelatihan Kerja',
          prop: 'pelatihanKerja',
          desc: 'pelatihankerja'
        },
        {
          title: 'Upaya Fisik',
          prop: 'upayaFisik',
          desc: 'syaratfisik'
        },
        {
          title: 'Bakat',
          prop: 'bakat',
          desc: 'bakat'
        },
        {
          title: 'Minat',
          prop: 'minat',
          desc: 'minat'
        },
        {
          title: 'Temperamen',
          prop: 'temperamen',
          desc: 'tempramen'
        },
      ],
      modal: {
        title: 'Test Modal',
        list: []
      },
      master: {
        pendidikan: [],        
        upayafisik: [],
        bakat: [],
        minat: [],
        temperamen: [],
        kodeJabatan: [],
        kompetensi: [],
      },
      selectedMaster: '',
      syaratHeader: [
        {
          prop: 'pendidikan'
        },
        {
          prop: 'kompetensi'
        },
        {
          prop: 'upayaFisik'
        },
        {
          prop: 'bakat'
        },
        {
          prop: 'minat'
        },
        {
          prop: 'tempramen'
        }
      ],
      options: [],
      value1: [],
      masterDetail: [
        {
          id: 1,
          name: 'Nama Jabatan'
        },
        {
          id: 2,
          name: 'Kode Jabatan'
        },
        {
          id: 3,
          name: 'Ringkasan Uraian Tugas'
        },
        {
          id: 4,
          name: 'Rincian Tugas'
        },
        {
          id: 5,
          name: 'Pendidikan'
        },
        {
          id: 12,
          name: 'Kompetensi'
        },
        {
          id: 6,
          name: 'Pengetahuan Kerja'
        },
        {
          id: 7,
          name: 'Pelatihan Kerja'
        },
        {
          id: 8,
          name: 'Upaya Fisik'
        },
        {
          id: 9,
          name: 'Bakat'
        },
        {
          id: 10,
          name: 'Minat'
        },
        {
          id: 11,
          name: 'Temperamen'
        }
      ],
      tooltipSyarat: [
        'Dinyatakan menurut tingkat dan jenisnya yang dibuktikan dengan sertifikat atau ijasah',
        'Kompetensi yang harus dimiliki oleh pemegang jabatan untuk bisa melakukan pekerjaan dengan sebaik-baiknya',
        'Kualifikasi yang harus dimiliki oleh pemegang jabatan untuk bisa melakukan pekerjaan dengan sebaik-baiknya',
        'Kegiatan terencana dan kontinyu untuk memperoleh keterampilan kerja',
        'Penggunaan fisik meliputi penggunaan organ tubuh  tangan, kaki, lengan, telapak tangan serta jari tangan, sendi pergelangan tangan, lengan, bahu, punggung, mata, telinga, hidung dan mulut',
        'Kapasitas potensial minimal yang dimiliki seseorang agar ia dapat melaksanakan tindak kerja sesuai dengan tuntutan tugas jabatan',
        'Kecenderungan untuk terserap dalam suatu pengalaman dan mengembangkannya, sedangkan keengganan, merupakan kecenderungan untuk menghindari sesuatu',
        'Kemampuan menyesuaikan diri yang harus dipenuhi pekerja untuk bekerja sesuai data jabatan'
      ],
      rincianTugasTooltip: 'Paparan atau bentangan atas semua tugas jabatan yang merupakan upaya pokok yang dilakukan pemegang jabatan dalam memproses bahan kerja menjadi hasil kerja dengan menggunakan perangkat kerja dan dalam kondisi pelaksanaan tertentu',
      ringkasanTugasTooltip: 'Merupakan uraian singkat yang menggambarkan ruang lingkup  tugas jabatan yang disusun  dalam satu kalimat',
      padananNamaJabatanTooltip: 'Tooltip Padanan Nama Jabatan',
      loadingKodeJabatanSearch: false,
      informasi_id: '',
      tooltipTriggerList: null,
      tooltipList: null,
      unduhDisable: false,
      isUserLogin: false
    }
  },
  created () {
    this.informasi_id = this.$route.params.id
    const user = auth.getUser()
    this.isUserLogin =  user && user !== null && user !== undefined && user !== '' ? true : false
  },
  async mounted () {
    await this.getDetailTkaPhp()
    // await this.getDetail()
    await this.getMasterTkaSunting()
    this.dataExist = true
    this.tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    this.tooltipList = [...this.tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
  },
  computed: {
    getModalTitleTka () {
      return this.modal.title
    },
    getSuntingTkaParams () {
      // let isSunting = false
      // let isKomentar = false
      // let isTambah = false
      let type = 0
      let detailType = 0
      let updatedPayload = []
      let finalData = []
      let isStop = false

      let detailTypeFind = this.masterDetail.find(item => item.name == this.modal.title)
      detailType = detailTypeFind?.id ? detailTypeFind.id : 0

      console.log('detailType', detailType)
      this.modal.list.forEach(data => {
        let isSunting = false
        let isKomentar = false
        let isTambah = false

        let isDataSunting = false
        if (data.editedVal && data.editedVal !== '') {
          isSunting = true
          isDataSunting = true
        } else if (data.komentar && data.komentar !== '') {
          isKomentar = true
          isDataSunting = true
        } else if (data.currVal == `Tambahan data ${this.modal.title}`) {
          if (data?.currVal == '' || data?.komentar == '') {
            isStop = true
          }
          isTambah = true
          isDataSunting = true
        }

        if (isDataSunting) {
          // updatedPayload.push(data)

          if (isTambah) {
            type = 3
          } else if (isSunting && isKomentar && !isTambah) {
            type = 4
          } else if (isSunting && !isKomentar && !isTambah) {
            type = 1
          } else if (!isSunting && isKomentar && !isTambah) {
            type = 2
          }
          // kirimkepuspita@gmail.com
          const user = auth.getUser()
          console.log('user', user)
          const template = {
          informasi_id: this.data.jabatanId,
          detail_id: detailType,

          user_email: user.email,
          // user_email: 'kirimkepuspita@gmail.com',
          // user_email: 'test@mail.com',

          // payload: this.modal.list,
          // title: this.modal.title,
          type_id: type
        }
        
          let payload = {
            fullPayload: this.modal.list,
            suntingOnlyPayload: [data]
          }
          console.log('final payload', payload)
          let sunting = { ...template, payload: JSON.stringify(payload) }

          finalData.push(sunting)
        }
      })
      return isStop ? [] : finalData
    }
  },
  watch: {
    getModalTitleTka (val) {
      if (val == 'Pendidikan' || val == 'Kompetensi' || val == 'Upaya Fisik' || val == 'Bakat' || val == 'Minat' || val == 'Temperamen') {
        switch (val) {
          case 'Pendidikan':
            // this.options = JSON.parse(JSON.stringify(this.master.pendidikan))
            this.selectedMaster = 'pendidikan'
            break;
          case 'Kompetensi':
            // this.options = JSON.parse(JSON.stringify(this.master.kompetensi))
            this.selectedMaster = 'kompetensi'
            break;
          case 'Upaya Fisik':
            // this.options = JSON.parse(JSON.stringify(this.master.upayaFisik))
            this.selectedMaster = 'upayafisik'
            break;
          case 'Bakat':
            // this.options = JSON.parse(JSON.stringify(this.master.bakat))
            this.selectedMaster = 'bakat'
            break;
          case 'Minat':
            // this.options = JSON.parse(JSON.stringify(this.master.minat))
            this.selectedMaster = 'minat'
            break;
          case 'Temperamen':
            // this.options = JSON.parse(JSON.stringify(this.master.tempramen))
            this.selectedMaster = 'temperamen'
            break;
        }
      }
    }
  },
  methods: {
    async getDetailTkaPhp () {
      try {
        const res = await api.getDetailTkaPhp(this.$route.params.id)
        console.log('detail', res)

        const dataDetail = {
          jabatanId: res.id,
          nama_jabatan: res.nama_jabatan,
          kode_jabatan: res.kode_jabatan,
          kode_sector: res.sector.kode,
          kode: res.subsector[0].ss_kode,
          sector: res.sector.sector,
          sectorId: res.sector.id,
          desc: res.subsector[0].subsector,
          subSectorId: res.subsector[0].id,
          ringkasan_uraian_tugas: res.ringkasan_uraian_tugas,
          rincianTugas: res.d_rinciantugas,
          padanan_nama_jabatan: res.padanan_nama_jabatan,
          syarat: {
            pendidikan: res.r_pendidikan,
            kompetensi: res.r_kompetensi,
            pengetahuanKerja: res.d_pengetahuankerja,
            pelatihanKerja: res.d_pelatihankerja,
            upayaFisik: res.r_syaratfisik,
            bakat: res.r_bakat,
            minat: res.r_minat,
            temperamen: res.r_tempramen,
          }
        }

        console.log('dataDetail', dataDetail)
        this.data = JSON.parse(JSON.stringify(dataDetail))
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    async getMasterTkaSunting () {
      try {
        const res = await api.phpGetSuntingTkaMaster()
        console.log('phpmaster sunting', res)

        // let params = { keyword: this.data.kode_jabatan, limit: 25, offset: 0 } // express
        let params = { limit: 25, keyword: '' }
        // const res2 = await api.quickSearch3(params) // express
        // const res2 = await api.quickSearchPhpNonKeyword(params)
        const res2 = await api.getSearchKbji(params)

        // this.master.kodeJabatan = JSON.parse(JSON.stringify(res2.data)) // express
        console.log('res', res)
        this.master.kodeJabatan = JSON.parse(JSON.stringify(res2.data)) // php
        this.master.pendidikan = JSON.parse(JSON.stringify(res[0].data.map(data => { return { ...data, label: 'pendidikan' } }) ))
        this.options = JSON.parse(JSON.stringify(res[0].data.map(data => { return { ...data, label: 'pendidikan' } }).slice(0, 50) ))
        this.master.upayafisik = JSON.parse(JSON.stringify(res[1].data.map(data => { return { ...data, label: 'syaratfisik' } })))
        this.master.bakat = JSON.parse(JSON.stringify(res[2].data.map(data => { return { ...data, label: 'bakat' } })))
        this.master.minat = JSON.parse(JSON.stringify(res[3].data.map(data => { return { ...data, label: 'minat' } })))
        this.master.temperamen = JSON.parse(JSON.stringify(res[4].data.map(data => { return { ...data, label: 'tempramen' } })))
        this.master.kompetensi = JSON.parse(JSON.stringify(res[5].data.map(data => { return { ...data, label: 'kompetensi' } })))
        this.master.ragamdisabilitas = JSON.parse(JSON.stringify(res[6].data.map(data => { return { ...data, label: 'ragamdisabilitas' } })))
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error when fetching master'
        })
      }
    },
    async pendidikanMasterSearch (query) {
      try {
        if (query == '') {
          this.options = this.master.pendidikan.slice(0, 50)
          return ''
        }
        // const res = await api.expressRemoteMasterSearch(query)
        // const data = res.data
        // this.master.pendidikan = JSON.parse(JSON.stringify(data.map(data => { return { ...data, label: 'pendidikan' } }).slice(0, 100) ))
        this.options = this.master.pendidikan.filter(data => {
          if (data.pendidikan.toLowerCase().includes(query.toLowerCase()))
          return data
        }).slice(0, 50)
        // console.log('filteredData', filteredData)
        // this.options = JSON.parse(JSON.stringify(filteredData))
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error when fetching sunting remote search pendidikan'
        })
      }
    },
    debounce (func, timeOut = 300) {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          func.apply(this, args)
        }, timeOut)
      }
    },
    async kodeJabatanMasterSearch (query) {
      try {
        let res = {}
        // let params = { keyword: query, limit: 25, offset: 0 } // express
        let params = { limit: 25, keyword: query } // php
        // const keyword = query // php
        // res = await api.quickSearch3(params) // express
        this.loadingKodeJabatanSearch = true
        res = await api.getSearchKbji(params) // php
        // const data = res.data ? res.data : [] // express
        const data = res.data ? res.data : [] // php
        this.master.kodeJabatan = JSON.parse(JSON.stringify(data))
      } catch (err) {
        console.log('err', err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error when fetching sunting remote search kode jabatan'
        })
      } finally {
        this.loadingKodeJabatanSearch = false
      }
    },
    toTka (sector = null, subsector = null) {
      if (!sector && !subsector) {
        if (this.$route.path !== '/home/tka') this.$router.push('/home/tka')
      } else {
        let query = ''
        query += sector ? `sector=${sector}` : ''
        query += subsector ? `&subSector=${subsector}` : ''
        this.$router.push(`/home/tka?${query}`)
      }
    },
    toSignIn () {
      this.$router.push('/sign_in')
    },
    toSuntingTka (title, data) {

      if (!auth.getUser()) {
        window.location.href = `https://account.kemnaker.go.id/auth?response_type=code&client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_LOGIN_URL}&scope=basic email`
        // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=https://dev.otellogroup.id/&scope=basic email'
        // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=http://localhost:8080/&scope=basic email'
        return
      }
      this.modal.list = []
      let newData = {}
      this.modal.title = title

      console.log('data sunting', data)

      switch (title) {
        case 'Nama Jabatan':
          newData = {
            kode_jabatan: this.data.kode_jabatan,
            nama_jabatan: this.data.nama_jabatan,
            detail_name: this.modal.title,
            currVal: data.nama_jabatan,
            editedVal: '',
            komentar: ''
          }
          this.modal.list.push(newData)
          break;
        case 'Kode Jabatan':
          newData = {
            kode_jabatan: this.data.kode_jabatan,
            nama_jabatan: this.data.nama_jabatan,
            detail_name: this.modal.title,
            currVal: data.kode_jabatan,
            editedVal: '',
            komentar: ''
          }
          this.modal.list.push(newData)
          break;
        case 'Ringkasan Uraian Tugas':
          newData = {
            kode_jabatan: this.data.kode_jabatan,
            nama_jabatan: this.data.nama_jabatan,
            detail_name: this.modal.title,
            currVal: data.ringkasan_uraian_tugas,
            editedVal: '',
            komentar: ''
          }
          this.modal.list.push(newData)
          break;
        case 'Rincian Tugas':
          for (let i = 0; i < data.length; i++) {
            newData = {
              kode_jabatan: this.data.kode_jabatan,
              nama_jabatan: this.data.nama_jabatan,
              detail_name: this.modal.title,
              currVal: data[i].rincian_tugas,
              editedVal: '',
              komentar: '',
              id: data[i].id
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Pendidikan':
          for (let i = 0; i < data.length; i++) {
            newData = {
              kode_jabatan: this.data.kode_jabatan,
              nama_jabatan: this.data.nama_jabatan,
              detail_name: this.modal.title,
              currVal: data[i].pendidikan,
              editedVal: '',
              komentar: '',
              id: data[i].d_id,
              kode: data[i].r_kode
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Kompetensi':
          for (let i = 0; i < data.length; i++) {
            newData = {
              kode_jabatan: this.data.kode_jabatan,
              nama_jabatan: this.data.nama_jabatan,
              detail_name: this.modal.title,
              currVal: data[i].kompetensi,
              editedVal: '',
              komentar: '',
              id: data[i].d_id,
              kode: data[i].r_kode
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Pengetahuan Kerja':
          for (let i = 0; i < data.length; i++) {
            newData = {
              kode_jabatan: this.data.kode_jabatan,
              nama_jabatan: this.data.nama_jabatan,
              detail_name: this.modal.title,
              currVal: data[i].pengetahuan_kerja,
              editedVal: '',
              komentar: '',
              id: data[i].id
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Pelatihan Kerja':
          for (let i = 0; i < data.length; i++) {
            newData = {
              kode_jabatan: this.data.kode_jabatan,
              nama_jabatan: this.data.nama_jabatan,
              currVal: data[i].pelatihankerja,
              editedVal: '',
              komentar: '',
              id: data[i].d_id,
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Upaya Fisik':
          for (let i = 0; i < data.length; i++) {
            newData = {
              kode_jabatan: this.data.kode_jabatan,
              nama_jabatan: this.data.nama_jabatan,
              detail_name: this.modal.title,
              currVal: data[i].syaratfisik,
              editedVal: '',
              komentar: '',
              id: data[i].d_id,
              kode: data[i].r_kode
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Bakat':
          for (let i = 0; i < data.length; i++) {
            newData = {
              kode_jabatan: this.data.kode_jabatan,
              nama_jabatan: this.data.nama_jabatan,
              detail_name: this.modal.title,
              currVal: data[i].bakat,
              editedVal: '',
              komentar: '',
              id: data[i].d_id,
              kode: data[i].r_kode
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Minat':
          for (let i = 0; i < data.length; i++) {
            newData = {
              kode_jabatan: this.data.kode_jabatan,
              nama_jabatan: this.data.nama_jabatan,
              detail_name: this.modal.title,
              currVal: data[i].minat,
              editedVal: '',
              komentar: '',
              id: data[i].d_id,
              kode: data[i].r_kode
            }
            this.modal.list.push(newData)
          }
          break;
        case 'Temperamen':
          for (let i = 0; i < data.length; i++) {
            newData = {
              kode_jabatan: this.data.kode_jabatan,
              nama_jabatan: this.data.nama_jabatan,
              detail_name: this.modal.title,
              currVal: data[i].tempramen,
              editedVal: '',
              komentar: '',
              id: data[i].d_id,
              kode: data[i].r_kode
            }
            this.modal.list.push(newData)
          }
          break;
      }
      this.$refs.suntingModalBtn.click()
    },
    modalSuntingAddDataTka (title) {
      const tableBottom = this.$refs.tableBottom
      console.log('tableBottom', tableBottom)
      const newData = {
        title: title,
        kode_jabatan: this.data.kode_jabatan,
        nama_jabatan: this.data.nama_jabatan,
        kode_sector: this.data.kode_sector,
        kode_subsector: this.data.kode,
        currVal: `Tambahan data ${this.modal.title}`,
        editedVal: '',
        komentar: ''
      }
      this.modal.list.push(newData)
      setTimeout(() => {
        tableBottom.scrollIntoView({behavior: 'smooth', block: 'end'});
      }, 200)
    },
    selectFilter (val) {
      let key = ''
      let list = []

      switch (this.modal.title) {
        case 'Pendidikan':
          list = JSON.parse(JSON.stringify(this.master.pendidikan))
          break;
        case 'Kompetensi':
          list = JSON.parse(JSON.stringify(this.master.kompetensi))
          break;
        case 'Upaya Fisik':
          list = JSON.parse(JSON.stringify(this.master.syaratfisik))
          break;
        case 'Bakat':
          list = JSON.parse(JSON.stringify(this.master.bakat))
          break;
        case 'Minat':
          list = JSON.parse(JSON.stringify(this.master.minat))
          break;
        case 'Temperamen':
          list = JSON.parse(JSON.stringify(this.master.temperamen))
          break;
      }
      let newOption = JSON.parse(JSON.stringify(list))
      if (val == '') {
        this.options = JSON.parse(JSON.stringify(list))
      }
      
      switch (this.modal.title) {
        case 'Pendidikan':
          key = 'pendidikan'
          break;
        case 'Kompetensi':
          key = 'kompetensi'
          break;
        case 'Upaya Fisik':
          key = 'syaratfisik'
          break;
        case 'Bakat':
          key = 'bakat'
          break;
        case 'Minat':
          key = 'minat'
          break;
        case 'Temperamen':
          key = 'temperamen'
          break;
      }

      const filteredOptions = newOption.filter(option => option[key].toLowerCase().includes(val.toLowerCase()))
      console.log('filteredOptions', filteredOptions)
      // return filteredOptions
      this.options = JSON.parse(JSON.stringify(filteredOptions))
    },
    async submitSunting () {
      const params = this.getSuntingTkaParams
      console.log ('submited params', params)
      // console.log ('payload', JSON.parse(params.payload))
      const closeModal = this.$refs.closeSuntingModalBtn

      if (params.length < 1) {
        swal.fire({
          text: 'Tidak Ada Perubahan Suntingan',
          icon: 'info',
          confirmButtonText: 'Oke',
          confirmButtonColor: '#15406A',
        })
        return ''
      }

      const swalRes = await swal.fire({
        title: 'Submit Sunting?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Batal',
        confirmButtonColor: '#15406A',
        cancelButtonColor: '#d33'
      })
      if (!swalRes.isConfirmed) return ''
      const loadprg = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      try {
        const user = auth.getUser()
        const middlewareParams = {
          email: user.email,
          token: auth.getSSOToken()
        }
        // if (params.type == 0) console.log('no change has been submited')
        // const res = await api.submitSunting(params)
        const res = await api.submitSunting2tka(params, middlewareParams, { Authorization: `Bearer ${auth.getToken()}` })
        // const res = await api.submitSuntingLocal(params)
        // const res = await api.submitSuntingLocal2(params)
        swal.fire({
          icon: 'success',
          title: 'Sunting Berhasil',
          text: 'Berhasil melakukan sunting',
          // confirmButtonColor: '#15406A',
        })
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          // title: 'Error',
          text: 'Gagal melakukan sunting'
        })
      } finally {
        loadprg.close()
        closeModal.click()
      }
      // closeModal.click()
    },
    async draftSunting () {
      const params = this.getSuntingTkaParams
      console.log ('submited params', params)
      // console.log ('payload', JSON.parse(params.payload))
      const closeModal = this.$refs.closeSuntingModalBtn

      if (params.length < 1) {
        swal.fire({
          text: 'Tidak Ada Perubahan Suntingan',
          icon: 'info',
          confirmButtonText: 'Oke',
          confirmButtonColor: '#15406A',
        })
        return ''
      }

      const swalRes = await swal.fire({
        title: 'Draft Sunting?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Draft',
        cancelButtonText: 'Batal',
        confirmButtonColor: '#15406A',
        cancelButtonColor: '#d33'
      })
      if (!swalRes.isConfirmed) return ''
      const loadprg = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      try {
        const user = auth.getUser()
        const middlewareParams = {
          email: user.email,
          token: auth.getSSOToken()
        }
        // const res = await api.draftSunting(params)
        const res = await api.draftSuntingtka(params, middlewareParams, { Authorization: `Bearer ${auth.getToken()}` })
        swal.fire({
          icon: 'success',
          title: 'Draft Berhasil',
          text: 'Berhasil draft sunting',
          // confirmButtonColor: '#15406A',
        })
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error input draft sunting'
        })
      } finally {
        loadprg.close()
        closeModal.click()
      }
      // closeModal.click()
    },
    async generatePdf () {
      if (this.unduhDisable) return ''
      const user = auth.getUser()

      if (user && user !== null && user !== undefined && user !== '') {
        try {
          const params = {
            email: user.email,
            ref: 2,
            rid: this.informasi_id,
            token: auth.getSSOToken()
          }
          console.log('params', params)
          const res = await apiKJN.postDownloadLog(params, { Authorization: `Bearer ${auth.getToken()}` })
          console.log('res', res)
          this.$refs.html2Pdf.generatePdf()  
        } catch (err) {
          console.log(err)
          swal.fire({
            icon: 'error',
            title: `Gagal Unduh KJN`
          })
        }
      } else {
        window.location.href = `https://account.kemnaker.go.id/auth?response_type=code&client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_LOGIN_URL}&scope=basic email`
        // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=https://dev.otellogroup.id/&scope=basic email'
        // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=http://localhost:8080/&scope=basic email'
      }
    },
    failedGeneratePdf () {
      this.unduhDisable = true
    },
    modalBtnClick () {
      console.log('modalBtnClicked')
      setTimeout(() => {
        this.tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        this.tooltipList = [...this.tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
        console.log('tooltipList', this.tooltipList)
      }, 500)
      // this.tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
      // this.tooltipList = [...this.tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
    }
  }
}
</script>

<style>
  @import url('../../assets/styles/detail.css');
  @import url('../../assets/styles/scroller.css');
  .tooltip-inner {
    text-align: left;
  }
  .detail-clickable {
    color: white;
    cursor: pointer;
  }

  .sunting-btn {
    cursor: pointer;
  }

  .modal {
    z-index: 9000;
  }

  .el-select-dropdown {
    z-index: 9001 !important;
  }

  .el-select {
    /* display: block;
    padding-right: 8px; */
    width: 100%;
  }

  .btn-ternary-custom {
    color: #000;
    background-color: #26a59a !important;
    border-color: #26a59a !important;
    /* word-wrap: normal;
    word-break: normal; */
    white-space: nowrap;
  }
  
  @media (min-width: 768px) {
    .tooltip-inner {
      max-width: 40vw !important;
    }
  }

  @media (max-width: 767.98px)  {
    .tooltip-inner {
      max-width: 75vw !important;
    }
  }
</style>